import styled from 'styled-components';

export const Content = styled.div`
  display: flex;

  main {
    flex: 1;
  }

  form.create-project-form {
    width: 800px;
    margin: 64px auto;

    background: #ffffff;
    border: 1px solid #d3e2e5;
    border-radius: 20px;

    padding: 64px 80px;

    overflow: hidden;
  }

  form.create-project-form .leaflet-container {
    margin-bottom: 40px;
    border: 1px solid #d3e2e5;
    border-radius: 20px;
  }

  form.create-project-form fieldset {
    border: 0;
  }

  form.create-project-form fieldset + fieldset {
    margin-top: 80px;
  }

  form.create-project-form fieldset legend {
    width: 100%;

    font-size: 32px;
    line-height: 34px;
    color: #5c8599;
    font-weight: 700;

    border-bottom: 1px solid #d3e2e5;
    margin-bottom: 40px;
    padding-bottom: 24px;
  }

  form.create-project-form .input-block + .input-block {
    margin-top: 24px;
  }

  form.create-project-form .input-block label,
  form.create-project-form .mapTip {
    display: flex;
    color: #8fa7b3;
    margin-bottom: 8px;
    line-height: 24px;
  }

  form.create-project-form .input-block label span {
    font-size: 14px;
    color: #8fa7b3;
    margin-left: 24px;
    line-height: 24px;
  }

  form.create-project-form .input-block input,
  form.create-project-form .input-block textarea,
  form.create-project-form .input-block select {
    width: 100%;
    background: #f5f8fa;
    border: 1px solid #d3e2e5;
    border-radius: 20px;
    outline: none;
    color: #5c8599;
  }

  form.create-project-form .input-block input,
  form.create-project-form .input-block select {
    height: 64px;
    padding: 0 16px;
  }

  form.create-project-form .input-block textarea {
    min-height: 120px;
    max-height: 240px;
    resize: vertical;
    padding: 16px;
    line-height: 28px;
  }

  form.create-project-form .input-block .images-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px;
  }

  form.create-project-form .input-block .images-container img {
    width: 100%;
    height: 96px;
    object-fit: cover;
    border-radius: 20px;
  }

  form.create-project-form .input-block .images-container .new-image {
    height: 96px;
    background: #f5f8fa;
    border: 1px dashed #96d2f0;
    border-radius: 20px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  form.create-project-form .input-block input[type='file'] {
    display: none;
  }

  form.create-project-form .input-block .button-select {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  form.create-project-form .input-block .button-select button {
    height: 64px;
    background: #f5f8fa;
    border: 1px solid #d3e2e5;
    color: #5c8599;
    cursor: pointer;
    outline: none;
  }

  form.create-project-form .input-block .button-select button.active {
    background: #edfff6;
    border: 1px solid #a1e9c5;
    color: #37c77f;
  }

  form.create-project-form .input-block .button-select button.delete-active {
    background: #ffcad4;
    border: 1px solid #ff9690;
    color: #ff6690;
  }

  form.create-project-form .input-block .button-select button:first-child {
    border-radius: 20px 0px 0px 20px;
  }

  form.create-project-form .input-block .button-select button:last-child {
    border-radius: 0 20px 20px 0;
    border-left: 0;
  }

  form.create-project-form button.confirm-button {
    margin-top: 64px;

    width: 100%;
    height: 64px;
    border: 0;
    cursor: pointer;
    background: #3cdc8c;
    border-radius: 20px;
    color: #ffffff;
    font-weight: 800;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 0.2s;
  }

  form.create-project-form button.confirm-button svg {
    margin-right: 16px;
  }

  form.create-project-form button.confirm-button:hover {
    background: #36cf82;
  }
`;
